/*　include
------------------------------------------------------- */

import "core-js/stable";
import "regenerator-runtime/runtime";
import './scss/style.scss';

/*
------------------------------------------------------- */

let webStorage = function(){

if(sessionStorage.getItem('access')){
/* 2回目以降 */
document.addEventListener('DOMContentLoaded', (event) => {
    $('#js_splash').remove();
});
if (window.matchMedia('(max-width: 768px)').matches) {
  // ウィンドウサイズ768px以下のときの処理
const progress = document.getElementById("progress");
let videop = document.getElementById("mvVideo-sp");
function progressLoop() {
  setInterval(function () {
    document.getElementById("progress").value = Math.round(
      (videop.currentTime / videop.duration) * 100
    );
  });
}
  document.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(function() {
      document.getElementById("mvVideo-sp").play();
      videop.addEventListener("play", progressLoop);
      $('body').removeClass('hidden');
    }, 100);
  });
} else {
  // それ以外の処理
const progress = document.getElementById("progress");
let videop = document.getElementById("mvVideo-pc");
function progressLoop() {
  setInterval(function () {
    document.getElementById("progress").value = Math.round(
      (videop.currentTime / videop.duration) * 100
    );
  });
}
  document.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(function() {
      document.getElementById("mvVideo-pc").play();
      videop.addEventListener("play", progressLoop);
      $('body').removeClass('hidden');
    }, 100);
  });
}

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv').addClass('visible');
  }, 0);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_header_content').addClass('visible');
  }, 2200);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.el_franchise').addClass('visible');
  }, 2200);
});

    function spanWrap() {
      const target = document.querySelector('.el_splash h3');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrap();

    function spanWrapTtl() {
      const target = document.querySelector('.ly_mv_wrap_ttl h2');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrapTtl();

    function spanWrapTtlB() {
      const target = document.querySelector('.ly_mv_wrap_ttl_sub span');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrapTtlB();


document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".ly_mv_wrap_ttl h2 span").each(function(i) {
        setTimeout(function() {
            $(".ly_mv_wrap_ttl h2 span").eq(i).addClass("active");
        }, 150 * i);
    });
  }, 2700);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".ly_mv_wrap_ttl_sub span span").each(function(i) {
        setTimeout(function() {
            $(".ly_mv_wrap_ttl_sub span span").eq(i).addClass("active");
        }, 100 * i);
    });
  }, 4700);
});




}else{
sessionStorage.setItem('access', 'true');
/* 1回目 */
document.addEventListener('DOMContentLoaded', (event) => {
    $('#js_splash').removeClass('loaded');
});
if (window.matchMedia('(max-width: 768px)').matches) {
  // ウィンドウサイズ768px以下のときの処理
  const progress = document.getElementById("progress");
  let videop = document.getElementById("mvVideo-sp");
  function progressLoop() {
    setInterval(function () {
      document.getElementById("progress").value = Math.round(
        (videop.currentTime / videop.duration) * 100
      );
    });
  }
  document.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(function() {
      document.getElementById("mvVideo-sp").play();
      videop.addEventListener("play", progressLoop);
      $('body').removeClass('hidden');
    }, 6900);
  });
} else {
  // それ以外の処理
  const progress = document.getElementById("progress");
  let videop = document.getElementById("mvVideo-pc");
  function progressLoop() {
    setInterval(function () {
      document.getElementById("progress").value = Math.round(
        (videop.currentTime / videop.duration) * 100
      );
    });
  }
  document.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(function() {
      document.getElementById("mvVideo-pc").play();
      videop.addEventListener("play", progressLoop);
      $('body').removeClass('hidden');
    }, 6900);
  });
}

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv').addClass('visible');
  }, 6800);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_header_content').addClass('visible');
  }, 9000);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.el_franchise').addClass('visible');
  }, 9000);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.el_splash_inner_logo_ttl_img').addClass('none');
  }, 2000);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.el_splash h3').addClass('visible');
  }, 2100);
});

    function spanWrap() {
      const target = document.querySelector('.el_splash h3');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrap();

    function spanWrapTtl() {
      const target = document.querySelector('.ly_mv_wrap_ttl h2');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrapTtl();

    function spanWrapTtlB() {
      const target = document.querySelector('.ly_mv_wrap_ttl_sub span');
      const nodes = [...target.childNodes];

      let spanWrapText = ""

      nodes.forEach((node) => {
        if (node.nodeType == 3) {//テキストの場合
          const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
          //spanで囲んで連結
          spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
            return acc + `<span>${v}</span>`
          }, "");
        } else {//テキスト以外
          //<br>などテキスト以外の要素をそのまま連結
          spanWrapText = spanWrapText + node.outerHTML
        }
      })

      target.innerHTML = spanWrapText
    }

    spanWrapTtlB();


document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".ly_mv_wrap_ttl h2 span").each(function(i) {
        setTimeout(function() {
            $(".ly_mv_wrap_ttl h2 span").eq(i).addClass("active");
        }, 150 * i);
    });
  }, 9500);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".ly_mv_wrap_ttl_sub span span").each(function(i) {
        setTimeout(function() {
            $(".ly_mv_wrap_ttl_sub span span").eq(i).addClass("active");
        }, 100 * i);
    });
  }, 11500);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $("h3#typing span").each(function(i) {
        setTimeout(function() {
            $("h3#typing span").eq(i).addClass("active");
        }, 250 * i);
    });
  }, 2100);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('h3#typing span:nth-child(11)').addClass('red');
    $('h3#typing span:nth-child(12)').addClass('red');
    $('h3#typing span:nth-child(13)').addClass('red');
    $('h3#typing span:nth-child(14)').addClass('red');
  }, 5500);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#js_splash').addClass('done');
  }, 6800);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#js_splash').addClass('none');
  }, 7600);
});


  }

}

webStorage();

$("footer .footer-right_menu_shop .footer-right_menu_ttl").click(function () {
  $(".footer-right_menu_shop_main").slideToggle();
  $(this).toggleClass('clicked');
});

$("footer .footer-right_menu_menu .footer-right_menu_ttl").click(function () {
  $(".footer-right_menu_menu_main").slideToggle();
  $(this).toggleClass('clicked');
});


$(window).on('load resize', function(){
  let w = $(window).width();
  if(w <= 768){
    // ウィンドウサイズ768px以下のときの処理
    $(document).on('click',function(e) {
   if(!$(e.target).closest('#result li').length) {
         // ターゲット要素の外側をクリックした時の操作
            $('#result').removeClass('hover');
            $('.js_bg').remove();
            $('#result').addClass('clear');
            setTimeout(function(){
              $('.js_bg').addClass('hidden');
            }, 500);
       } else {
         // ターゲット要素をクリックした時の操作
            $('#result').addClass('hover');
            $('#result').removeClass('clear');
              setTimeout(function(){
                $('.js_bg.hidden').removeClass('hidden');
              }, 500);
              $('.bg1').append('<li class="js_bg hidden"><a href="menu-details.html"><h3 class="js_bg_ttl">伝説盛り<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>1</span></div></a></li>');
              $('.bg2').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">伝説盛りJr.<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>2</span></div></a></li>');
              $('.bg3').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">ぶ厚いタン<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>3</span></div></a></li>');
              $('.bg4').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">ぶ厚いハラミ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>4</span></div></a></li>');
              $('.bg5').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">ぶ厚いヒレ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>5</span></div></a></li>');
              $('.bg6').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">壺漬けBBQデカハラミ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>6</span></div></a></li>');
              $('.bg7').append('<li class="js_bg hidden"><a href=""><h3 class="js_bg_ttl">ぜったいカルビ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖厚切り焼肉ここにあり！！<br>ぶ厚いタン、ぶ厚いハラミ、ぶ厚いヒレの豪華盛り合わせ。</p><div class="js_bg_num">#<span>7</span></div></a></li>');
       }
    });
  } else {
    // それ以外の処理
    $('.ly_special .ly_special_wrap .ly_special_main ul').hover(
        function() {
            //マウスカーソルが重なった時の処理
            $(this).addClass('hover');
            $(this).removeClass('clear');
        },
        function() {
            //マウスカーソルが離れた時の処理
            $(this).removeClass('hover');
            $('.js_bg').remove();
            $(this).addClass('clear');
        }
    );
    $('.ly_special .ly_special_wrap .ly_special_main ul li').hover(
        function() {
            //マウスカーソルが重なった時の処理
              setTimeout(function(){
              $('.js_bg.hidden').removeClass('hidden');
              }, 500);
              $('.bg1').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">伝説盛り<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>元祖ぶ厚い焼肉店「焼肉ここから」の代名詞！伝説盛りです。超極厚のタン、ハラミ、ヒレ3種盛り合わせ。まさに伝説はここから始まった・・・　ぶ厚くカットするからこそ味わえる肉汁たっぷりのジューシーさをご堪能下さい。焼き加減が分からないお客様にはスタッフがお手伝いさせていただきます。焼肉の魅力を提案し続ける「焼肉ここから」で新しい焼肉を発見してみませんか!</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/1.png" style="width: 48px;"></div></li>');
              $('.bg2').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">伝説盛りJr.<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>「焼肉ここから」の店名を冠するメニュー”ここから盛り”は赤身肉とホルモンのMIX盛り合わせ！様々な部位を一度に楽しめるワクワク盛り。サイズもS・M・L・XLと選べるのでおひとり様から大人数まで全てのお客様におすすめの商品です。味付けもタレと塩で選べますし、ニンニクの量や辛さもお好みでアレンジ出来ます。おすすめはニンニク多めで中辛！お酒が美味しくすすみますよ。</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/2.png" style="width: 57px;"></div></li>');
        $('.bg3').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">ぶ厚いタン<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>人気の高いカルビ、ハラミ、ロースの盛り合わせ！質の良い脂で口いっぱいに旨味が広がるカルビ、低カロリーで女性人気も高いハラミ、きめ細かい肉質で柔らかいロースをご堪能頂けます！サイズも並・大２種類ご用意しておりますので少人数から大人数まで全てのお客様にご満足いただける商品でございます。</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/3.png" style="width: 58px;"></div></li>');
              $('.bg4').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">ぶ厚いハラミ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>ぜったい美味しい！ぜったい食べて欲しい！そんな思いを込めて作ったこのメニューも今は「焼肉ここから」の超人気メニュー。もやしナムル、センナムル、ゼンマイ、ほうれん草４種類のナムルを混ぜ合わせ韓国のりをトッピングしております。ナムルはそれぞれ別々に食べるより一緒に食べた方がぜったい美味しい！是非お試し下さい。</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/4.png" style="width: 59px;"></div></li>');
              $('.bg5').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">ぶ厚いヒレ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>熱々の石鍋を使用し、カットした柔らかくジューシーなお肉を入れ秘伝のタレで味付けした「焼肉ここから」の人気焼き飯！ルーツはまかない飯。スタッフの中で好評だったため、商品化したところ人気爆発！今や「焼肉ここから」の名物とまで言われるメニューになりました。２〜３名様でシェアして召し上がれます。</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/5.png" style="width: 57px;"></div></li>');
              $('.bg6').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">壺漬けBBQデカハラミ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>秘伝の味噌もみダレにニンニクとコショウとヤンニョンを入れじっくりと絡ませた超特大サイズのハラミ！元祖ぶ厚い焼肉店の真骨頂。このハラミの大きさは地域最大級・・・かも。おすすめは表面をさっと焼いたら厚切りにカットして内側はミディアムレアがオススメです。軽ーくタレに潜らせご飯にワンバウンドさせたら口をいっぱいに広げて召し上がってください。上質な濃い肉の味が口の中に広がって最高に幸せな気分になれますよ。これは焼肉の魔法！</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/6.png" style="width: 58px;"></div></li>');
              $('.bg7').append('<li class="js_bg hidden"><h3 class="js_bg_ttl">ぜったいカルビ<img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/arrow-right.png"></h3><p>THEプルコギ系韓国風カルビ！薄くカットした大判の牛バラ肉をネギとニラと一緒にオリジナルのタレで絡めて焼き、最後に卵をかけて軽く回して仕上げたら出来上がり。濃いめに作ったタレは卵でまろやかになり、ご飯と一緒に食べるとご飯が止まらなくなる・・かも。いつもの焼肉とはちょっと違う感覚に慣れてしまうとやみつきになりますよ。</p><div class="js_bg_num"><img src="http://issei-kokokara.com/wp-content/themes/kokokara/img/7.png" style="width: 55px;"></div></li>');
        },
        function() {
            //マウスカーソルが離れた時の処理
            $('.js_bg').remove();
            setTimeout(function(){
            $('.js_bg').addClass('hidden');
            }, 500);
        }
    );
  }
});


const list = document.querySelector(".list");
const listChildren = list.children;

for (let item of listChildren) {
    item.addEventListener(
        "mouseover",
        function () {
            //リストアイテムのdata-targetを取得
            let target = item.dataset.target;
            //id="app"の要素を取得
            let el = document.getElementById("result");
            //id="app"のclassをクリア
            el.classList = "hover";
            //id="app"に上で取得したtargetをclass属性で追加
            el.classList.add(target);
        },
        false
    );
}

$(".ly_header_content_menu").click(function () {
  $(".ly_header_content").toggleClass('on');
  $(this).toggleClass('active');
  $('body').toggleClass('scroll');
});

$(function() {
  $('.fade').on('inview', function(event, isInView) {
    if (isInView) {
      $(this).addClass('fadeIn');
    } else {

    }
  });
});

$(function() {
  $('.fade').on('inview', function(event, isInView) {
    if (isInView) {
        setTimeout(function() {
          $('.fadeIn').addClass('fadeO');
        }, 600);
      $(this).addClass('fadeIn');
    } else {

    }
  });
});

$(function() {
  $('.fade-layer').on('inview', function(event, isInView) {
    if (isInView) {
          $(this).addClass('fadeIn');
    } else {

    }
  });
});

var touch = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

if(touch) {
  try {
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}

$(window).on('load resize', function(){
  let w = $(window).width();
  if(w <= 768){
    // ウィンドウサイズ768px以下のときの処理
  }else{
    // それ以外の処理
$(window).scroll(function(){
  if ($(window).scrollTop() > 1) {
    $('.ly_mv_scroll').addClass('fixed');
    $('.ly_header_logo').addClass('scroll');
    $('.ly_header_content').addClass('scroll');
    $('.el_franchise').addClass('scroll');
  } else {
    $('.ly_mv_scroll').removeClass('fixed');
    $('.ly_header_logo').removeClass('scroll');
    $('.ly_header_content').removeClass('scroll');
    $('.el_franchise').addClass('scroll');
  }
});

$(function(){

  var pos = 0;
  var header = $('header');

  $(window).on('scroll', function(){
    if($(this).scrollTop() < pos ){
      //上スクロール時ヘッダー表示
      header.addClass('fixed');
      header.removeClass('hidden');
      header.removeClass('default');
    }else{
      //下スクロール時ヘッダー非表示
      header.removeClass('fixed');
      header.addClass('hidden');
    }
    pos = $(this).scrollTop();
  });

});
  }
});

jQuery(window).scroll(function() {
  let scrollTop = jQuery(window).scrollTop(); // スクロール上部の位置
  let areaTop = jQuery("footer").offset().top; // 対象エリアの上部の位置
  let areaBottom = areaTop + jQuery("footer").innerHeight(); // 対象エリアの下部の位置

  if (scrollTop > areaTop && scrollTop < areaBottom) {
    jQuery("header").addClass("is-in"); // スクロールが対象エリアに入った場合
  } else {
    jQuery("header").removeClass("is-in"); // スクロールが対象エリアから出ている場合
  }
});

$(function(){
  var head = $(".js_sub-ttl");
  var content = $(head).html();
  var text = $.trim(content);
  var newHtml = "";
  text.split("").forEach(function(v) {
      newHtml += "<span>" + v + "</span>";
  });
  $(head).html(newHtml);
  var count = $(head).text().length;
  for(i = 1; i <= count; i++){
    $("span:eq(" + i + ")").css({
      "animation-delay": i/4 +"s",
    })
  }
})
$(function(){
  var head = $(".js_sub-ttl02");
  var content = $(head).html();
  var text = $.trim(content);
  var newHtml = "";
  text.split("").forEach(function(v) {
      newHtml += "<span>" + v + "</span>";
  });
  $(head).html(newHtml);
  var count = $(head).text().length;
  for(i = 1; i <= count; i++){
    $("span:eq(" + i + ")").css({
      "animation-delay": i/4 +"s",
    })
  }
})
$(function(){
  var head = $(".js_sub-ttl03");
  var content = $(head).html();
  var text = $.trim(content);
  var newHtml = "";
  text.split("").forEach(function(v) {
      newHtml += "<span>" + v + "</span>";
  });
  $(head).html(newHtml);
  var count = $(head).text().length;
  for(i = 1; i <= count; i++){
    $("span:eq(" + i + ")").css({
      "animation-delay": i/4 +"s",
    })
  }
})

$(function() {
  $('.js_sub-ttl span').on('inview', function(event, isInView) {
    if (isInView) {
        setTimeout(function() {
          $('.js_sub-ttl span').each(function(i) {
              setTimeout(function() {
                  $('.js_sub-ttl span').eq(i).addClass("active");
              }, 120 * i);
          });
            }, 400);
    } else {

    }
  });
});

$(function() {
  $('.js_sub-ttl02 span').on('inview', function(event, isInView) {
    if (isInView) {
        setTimeout(function() {
          $('.js_sub-ttl02 span').each(function(i) {
              setTimeout(function() {
                  $('.js_sub-ttl02 span').eq(i).addClass("active");
              }, 120 * i);
          });
        }, 400);
    } else {

    }
  });
});

$(function() {
  $('.js_sub-ttl03 span').on('inview', function(event, isInView) {
    if (isInView) {
        setTimeout(function() {
          $('.js_sub-ttl03 span').each(function(i) {
              setTimeout(function() {
                  $('.js_sub-ttl03 span').eq(i).addClass("active");
              }, 120 * i);
          });
        }, 500);
    } else {

    }
  });
});
